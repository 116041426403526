import {h} from 'preact';
import {useEffect, useState} from "preact/hooks";
import {Router} from 'preact-router';
import Loader from './loader'; // Import the Loader component

import Header from './header';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Profile from '../routes/profile';
import Footer from "./footer";
import Rating from "../routes/rating";

function App() {
    const [isLoading, setIsLoading] = useState(true); // Loading state

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false); // Update loading state
        }, 1000);

        const tg = window.Telegram.WebApp
        tg.expand()
    })
    return (
        <div id="app">
            {isLoading ? (
                <Loader/>
            ) : (
                <>
                    <Header/>
                    <main>
                        <Router>
                            <Rating path="/rating/"/>
                            <Home path="/"/>
                            <Profile path="/profile/"/>
                        </Router>
                    </main>
                    <Footer/>
                </>
            )}
        </div>)

}

export default App;
