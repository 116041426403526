import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';
import coinIco from '../../assets/rewards/coin.png';
import goldIco from '../../assets/rewards/gold.png';

const Header = () => (
	<header class={style.header}>
		<ul class={style.rewardList}>
            <li>
                <img class={style.rewardIco} src={goldIco} alt=""/> 1
            </li>
            <li>
                <img class={style.rewardIco} src={coinIco} alt=""/> 130
            </li>
        </ul>
	</header>
);

export default Header;
