import { h } from 'preact';
import style from './style.css';

const Loader = () => (
    <div className={style.loader}>
        <div className={style.loaderText}>
        Loading...
        </div>
    </div>
);

export default Loader;
