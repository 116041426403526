import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';
import rankingIco from '../../assets/icons/ranking-star.svg'
import userIco from '../../assets/icons/user.svg'
import crownIco from '../../assets/icons/crown.svg'

const Footer = () => (
	<footer class={style.footer}>
		<nav>
			<Link activeClassName="active" href="/rating">
				<img className={style.ico} src={rankingIco}/>
			</Link>
            <Link className={style.mainButton} activeClassName="active" href="/">
                <img className={style.ico} src={crownIco}/>
            </Link>
			<Link activeClassName="active" href="/profile">
                <img className={style.ico} src={userIco}/>
            </Link>
		</nav>
	</footer>
);

export default Footer;
